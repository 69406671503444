import React from "react";
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';

import './Form.css';




export function Form(props) {
    console.log("Props: " + JSON.stringify(props));

    const defaultValues = {
        firstName : "",
        lastName : "",
        email : props.email || "",
        phone : "",
        snowflakeHostname : "",
        serviceUser : "",
        servicePassword : "",
        dbName : "",
        bigIdUrl : "",
        bigIdToken : "",
        mapiKey : "to generate",
        mapiSecret : "to generate",
    };

    const [loading, setLoading] = React.useState(false);
    const [formValues, setFormValues] = React.useState(defaultValues);
    
    
    //snackbar
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const [open, setOpen] = React.useState(false);
    const [openFail, setOpenFail] = React.useState(false);
    const [openWarning, setOpenWarning] = React.useState(false);
    const [openInfo, setOpenInfo] = React.useState(false);
    const [openEnterprisePlus, setOpenEnterprisePlus] = React.useState(false);
    const [showFields, setShowFields] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpen(false);
        setOpenFail(false);
        setOpenWarning(false);
        setOpenInfo(false);
        setOpenEnterprisePlus(false);
    };



    function handleInputChange(e) {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    
    //button clicked
    function handleClick(e) {
        // alert(JSON.stringify(Object.keys(e)));
        console.log(formValues);
        //console.log(e);
        setLoading(true);


        if (Object.values(formValues).includes(""))
        {
            setOpenWarning(true);
            setLoading(false);
        }
        else
        {
            //TODO: Change this URL!
            axios.post(`https://c4cu5mgqxbztport4l5cil2bey0iuonf.lambda-url.us-east-1.on.aws/`, formValues, {
                "Content-Type"      : "application/json",
            })
            .then((data) => {
                console.log(data); // JSON data parsed by `data.json()` call
                setLoading(false);


                //if client has been provisioned an account, show new fields
                // and a message asking to fill in fields
                if (data.data?.status === 'client already exists')
                {
                    setOpenInfo(true);
                    setFormValues({
                        ...formValues,
                        'mapiKey': '',
                        'mapiSecret': ''
                    });
                    setShowFields(true);
                }
                else
                {
                    setOpen(true);
                }
                
                
                
            })
            .catch((error) => {

                console.error(error);
                setLoading(false);

                if (error.response?.status === 403)
                {
                    setOpenEnterprisePlus(true);
                }
                else
                {
                    setOpenFail(true);
                }

            });
        }



    }
    

    return (
        <div>
            <form  className="mainForm">
                <TextField 
                    id="firstName" 
                    label="First Name" 
                    variant="outlined" 
                    name="firstName"
                    type="text"
                    value={formValues.firstName}
                    onChange={handleInputChange} 
                    required /> 
                <TextField 
                    id="lastName" 
                    label="Last Name" 
                    variant="outlined"
                    name="lastName" 
                    value={formValues.lastName}
                    onChange={handleInputChange} 
                    required /><br />
                <TextField 
                    id="email" 
                    label="Email" 
                    variant="outlined"
                    name="email"
                    value={formValues.email}
                    onChange={handleInputChange} 
                    required />
                <TextField 
                    id="phone" 
                    label="Phone Number" 
                    variant="outlined"
                    name="phone"
                    value={formValues.phone}
                    onChange={handleInputChange} 
                    required /><br />
                <TextField 
                    id="snowflakeHostname" 
                    label="Snowflake Hostname" 
                    variant="outlined" 
                    name="snowflakeHostname"
                    placeholder="example.snowflakecomputing.com"
                    value={formValues.snowflakeHostname}
                    onChange={handleInputChange} 
                    required />
                <TextField 
                    id="dbName" 
                    label="Database Name" 
                    variant="outlined"
                    name="dbName"
                    value={formValues.dbName}
                    onChange={handleInputChange} 
                    required /><br />
                <TextField 
                    id="serviceUser" 
                    label="Service User" 
                    variant="outlined" 
                    name="serviceUser"
                    placeholder="ALTR_SERVICE_USER"
                    value={formValues.serviceUser}
                    onChange={handleInputChange} 
                    required/>
                <TextField 
                    id="servicePassword" 
                    label="Service Password" 
                    variant="outlined" 
                    name="servicePassword"
                    type="password"
                    value={formValues.servicePassword}
                    onChange={handleInputChange} 
                    required /><br />
                <TextField 
                    id="bigIdUrl" 
                    label="BigID URL" 
                    variant="outlined" 
                    name="bigIdUrl"
                    placeholder="example.bigid.com"
                    value={formValues.bigIdUrl}
                    onChange={handleInputChange} 
                    required />
                <TextField 
                    id="bigIdToken" 
                    label="BigID Access Token" 
                    variant="outlined"
                    name="bigIdToken"
                    value={formValues.bigIdToken}
                    onChange={handleInputChange} 
                    required /><br />

                { showFields ? <TextField 
                    id="mapiKey" 
                    label="ALTR API Key" 
                    variant="outlined"
                    name="mapiKey"
                    value={formValues.mapiKey}
                    onChange={handleInputChange} 
                    required /> : null }
                

                { showFields ? <TextField 
                    id="mapiSecret" 
                    label="ALTR API Secret" 
                    variant="outlined"
                    name="mapiSecret"
                    value={formValues.mapiSecret}
                    onChange={handleInputChange} 
                    required /> : null }
                <br />

                <LoadingButton
                    onClick={handleClick}
                    loading={loading}
                    variant="contained"
                    id="submitButton"
                >
                    Submit
                </LoadingButton>
            </form>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} >
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                Success!
            </Alert>
            </Snackbar>

            <Snackbar open={openFail} autoHideDuration={6000} onClose={handleClose} >
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                Error: Please contact support@altr.com for help
            </Alert>
            </Snackbar>

            <Snackbar open={openWarning} autoHideDuration={6000} onClose={handleClose} >
            <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
                Please fill out all fields
            </Alert>
            </Snackbar>

            <Snackbar open={openInfo} onClose={handleClose} >
            <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
                Account already exists - Please generate and input MAPI keys.
                This can be done in the ALTR portal, in the Settings -> Preferences page, in the API tab.
            </Alert>
            </Snackbar>

            <Snackbar open={openEnterprisePlus} onClose={handleClose} >
            <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
                Tokenization is unavailable in your edition of ALTR. 
                To unlock this feature, please upgrade your account.
            </Alert>
            </Snackbar>
        </div>
    )
}
