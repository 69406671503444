import React, { useState, useEffect } from 'react';
import './App.css';
import {Form} from "./Form.js";
import axios from 'axios';

import CircularProgress from '@mui/material/CircularProgress';


function App() {
  const [doneLoading, setDoneLoading] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");

  useEffect(() => {
    // Get token from URL
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);

    let tokenParam = urlParams.get('token');
    console.log("Token: " + tokenParam);

    //TODO: change this value
    axios.post(`https://zqplg2alavmbile5kezemlwncm0mqbah.lambda-url.us-east-1.on.aws/`, {token:tokenParam}, {
          "Content-Type"      : "application/json",
      })
      .then((data) => {
        console.log("Successful return");
        console.log(data); // JSON data parsed by `data.json()` call
        setEmailAddress(data.data.email);
        console.log("Email Address: " + emailAddress);
        setHasAccess(true);
        setDoneLoading(true);
      })
      .catch((error) => {
        console.log("Error code returned");
        setHasAccess(false);
        setDoneLoading(true);
        console.error(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <div className="altrImg">
        <a href="http://altr.com" rel="noreferrer" target="_blank"><img src="https://assets.website-files.com/625877bdadf57ec93447d6b4/6258e09090a5ef0686d5c53a_altr-logo.svg" alt="An ALTR Logo"
          height="50" width="120"></img></a>
      </div>
      
      <header className="App-header">

        <h1>ALTR + BigID Integration</h1>

        {doneLoading ? 
          hasAccess ? <Form email={emailAddress}/> : <h2>403 Access Forbidden</h2>
          : <CircularProgress />
        }

      </header>
    </div>
  );
}


export default App;
